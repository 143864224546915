<template>
    <div v-if="!loading" class="tw-h-[40px] tw-grid tw-grid-cols-3  tw-border tw-rounded tw-border-solid tw-border-gray-200">
        <div class="tw-px-3 tw-min-w-[50px]  tw-h-full tw-flex tw-justify-center tw-text-end tw-flex-col tw-border-e tw-border-solid tw-border-gray-200">
            <p class="tw-text-sm tw-font-bold">${{ parseFloat(total_spend).toFixed(2) }}</p>
            <p class="tw-text-[10px] tw-text-gray-600 -tw-mt-0.5">{{ $t('common.total-spend') }}</p>
        </div>
        <div class="tw-px-3 tw-min-w-[50px]  tw-h-full tw-flex tw-justify-center tw-text-end tw-flex-col tw-border-e tw-border-solid tw-border-gray-200">
            <p class="tw-text-sm tw-font-bold tw-text-cyan-500">{{ total_leads }}</p>
            <p class="tw-text-[10px] tw-text-gray-600 -tw-mt-0.5">{{ $t('common.total-leads') }}</p>
        </div>
        <div class="tw-px-3 tw-min-w-[50px]  tw-h-full tw-flex tw-justify-center tw-text-end tw-flex-col">
            <p class="tw-text-sm tw-font-bold tw-text-violet-500">${{ total_leads > 0 ? (total_spend / total_leads).toFixed(2) : total_leads }}</p>
            <p class="tw-text-[10px] tw-text-gray-600 -tw-mt-0.5">{{ $t('common.cost-per-lead') }}</p>
        </div>
    </div>
    <div v-if="loading" class="tw-h-[40px] tw-grid tw-grid-cols-3  tw-border tw-rounded tw-border-solid tw-border-gray-200">
        <div class="tw-px-3  tw-h-full tw-flex tw-justify-center tw-items-end tw-text-end tw-flex-col tw-border-e tw-border-solid tw-border-gray-200">
            <p class="tw-h-[20px] tw-font-bold"><icon icon="eos-icons:three-dots-loading" /></p>
            <p class="tw-text-[10px] tw-text-gray-600 -tw-mt-0.5">{{ $t('common.total-spend') }}</p>
        </div>
        <div class="tw-px-3  tw-h-full tw-flex tw-justify-center tw-items-end tw-text-end tw-flex-col tw-border-e tw-border-solid tw-border-gray-200">
            <p class="tw-h-[20px] tw-font-bold"><icon icon="eos-icons:three-dots-loading" class="tw-text-xl tw-text-cyan-500" /></p>
            <p class="tw-text-[10px] tw-text-gray-600 -tw-mt-0.5">{{ $t('common.total-leads') }}</p>
        </div>
        <div class="tw-px-3  tw-h-full tw-flex tw-justify-center tw-items-end tw-text-end tw-flex-col">
            <p class="tw-h-[20px] tw-font-bold"><icon icon="eos-icons:three-dots-loading" class="tw-text-xl tw-text-violet-500" /></p>
            <p class="tw-text-[10px] tw-text-gray-600 -tw-mt-0.5">{{ $t('common.cost-per-lead') }}</p>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps, toRef } from 'vue';

const props = defineProps(['kpis', 'loading']);
const kpis = toRef(props, 'kpis')
const loading = toRef(props, 'loading')

const total_leads = computed(() => kpis.value.results)
const total_spend = computed(() => kpis.value.spend)
</script>

<style>

</style>