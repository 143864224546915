<template>
    <div class="tw-relative ltw-z-[15] tw-w-full" ref="container">
        <FormVueSelect append-to-body hide-errors multiple :searchable="false" v-model="selectedValues" max-height="200" class="!tw-h-fit"
            :options="products" option-value="id" :label="$t('fields.product')" :error="errors.product_id &&
                $t('errors.' + errors.product_id, {
                    field: $t('fields.product'),
                })
                ">
            <template v-slot:option="{ option }">
                <p class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
                    option.name }}</p>
            </template>
            <template v-slot:selected-option-container="{ params }">
                <div class="vs__selected tw-text-xs tw-py-0 tw-px-2" :class="params.option.color" @click="removeValue(params.option.id)">
                    <p class="tw-max-w-[90px] tw-truncate">{{ params.option.label }}</p>
                    </div>
            </template>
        </FormVueSelect>

    </div>
</template>

<script setup>
import { useStateStore } from '@/stores/app/state';
import { onClickOutside } from '@vueuse/core';
import FormVueSelect from "@/components/form/FormVueSelect";
import { computed, ref, inject, watch } from 'vue';


const container = ref(null);
onClickOutside(container, () => false)//visible.value = false);

const filter = inject('filter')
const filters = inject('filters')

const options = ref([])

const visible = ref(false);
const stateStore = useStateStore();
const products = computed(() => [{ id: null }, ...stateStore.products.map(p => ({ ...p, label: p.name }))]);
const selected = computed({
    get: () => filters.value.product_id,
    set: v => filters.value.product_id = v
});

const errors = ref({})
const selectedValues = ref(filters.value.product_id);

watch(() => selectedValues.value, () => {
    selected.value = selectedValues.value
    
})

const removeValue = value => {
    selectedValues.value = selectedValues.value.filter(v => v != value)
}

const getOptions = async () => {
    // loading.value = true;
    // await productsApi.all()
    // .then(
    //     res => {
    //         if(res.data.code == 'SUCCESS') {
                options.value = products.value
                // options.value.map(c => products.value[c.id] = c.name)
    //         }
    //     }
    // )
    // loading.value = false;
}

getOptions()

const onFilter = () => {
    filter();
    visible.value = false;
}

onFilter

</script>

<style></style>