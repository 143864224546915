<template>
    <div v-if="!loading">
        <FormVueSelect append-to-body hide-errors multiple :searchable="true" v-model="selectedValues" max-height="200" class="!tw-h-fit"
            :options="options" option-value="id" :label="$t('fields.marketer_id')" :error="errors.marketer_id &&
                $t('errors.' + errors.marketer_id, {
                    field: $t('fields.marketer_id'),
                })
                ">
            <template v-slot:option="{ option }">
                <p class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
                    option.name }}</p>
            </template>
            <template v-slot:selected-option-container="{ params }">
                <div class="vs__selected tw-text-xs tw-py-0 tw-px-2" @click="removeValue(params.option.id)">{{ params.option.name
                    }}</div>
            </template>
        </FormVueSelect>
    </div>
</template>

<script setup>
// import { onClickOutside } from '@vueuse/core';
import users from '@/api/users';
import FormVueSelect from "@/components/form/FormVueSelect";
import { ref, watch, inject } from 'vue';
// import { useI18n } from "vue-i18n";

// const i18n = useI18n();
const updateFilters = inject('update:filters')
const marketers = ref([]);
const filters = inject('filters')
const options = ref([])
const loading = ref(false);

const errors = ref({})
const selectedValues = ref(filters.value.marketer_id ?? []);
watch(() => selectedValues.value, () => {
    const newFilters = {
        ...filters.value,
        marketer_id: selectedValues.value,
    }

    updateFilters(newFilters)
})



const removeValue = value => {
    
    selectedValues.value = selectedValues.value.filter(v => v != value)
}

const getOptions = async () => {
    loading.value = true;
    await users.byRoles(['marketer'])
    .then(
        res => {
            if(res.data.code == 'SUCCESS') {
                options.value = res.data.users
                options.value.map(c => marketers.value[c.id] = c.name)
            }
        }
    )
    loading.value = false;
}

getOptions()

</script>

<style></style>