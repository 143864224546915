<template>
    <tr :key="items"
        class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group tw-bg-gray-50 tw-duration-100 tw-border-t-gray-200 tw-border-t-2">
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-font-bold tw-text-end">{{ $t('common.total') }}:</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-1 tw-justify-start " >
                <p class="tw-truncate tw-font-bold">${{ total_spend.toFixed(2) }}</p>
            </div>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-1 tw-justify-start " :class="[total_leads == 0 ? 'tw-text-amber-500' : 'tw-text-emerald-500']">
                <p class="tw-truncate tw-font-bold">{{ total_leads }}</p>
                <icon icon="solar:chart-2-bold-duotone" />
            </div>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-1 tw-justify-start " :class="[total_leads == 0 ? 'tw-text-amber-500' : 'tw-text-violet-500']">
                <p class="tw-truncate tw-font-bold">${{ total_cpl }}</p>
            </div>
        </td>

        <td class="tw-px-5 tw-pr-0 tw-py-">
        </td>
    </tr>
</template>

<script setup>
import { computed } from "vue";
import { defineProps, toRef} from "vue";
const props = defineProps(["items"]);
const items = toRef(props, "items");

const total_spend = computed(() => {
    return items.value.reduce((total, item) => total + item.spend, 0);
});

const total_leads = computed(() => {
    return items.value.reduce((total, item) => total + item.results, 0);
});

const total_cpl = computed(() => {
    return total_leads.value > 0 ? (total_spend.value / total_leads.value).toFixed(2) : 0;
});

</script>

<style></style>