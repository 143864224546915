<template>
    <div :class="[visible ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]']"
        class="tw-grid tw-duration-300 tw-transition-all tw-pt-2">
        <div class=" tw-col-span-1 tw-overflow-hidden" :class="[visible && '!tw-overflow-visible']">
            <div class="tw-p-4 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-2 lg:tw-grid-cols-4">
                <MarketerFilter v-if="['admin'].includes($user.role)" />
                <SpentInFilter v-if="['admin'].includes($user.role)" />
                <ProductFilter v-if="['admin'].includes($user.role)" />
                <div class="tw-col-span-1 md:tw-col-span-2 lg:tw-col-span-4 tw-flex tw-items-center tw-justify-end">
                    <div class="tw-w-fit tw-flex tw-items-center tw-gap-2" >
                        <a-button @click="onFilter" intent="primary" class="tw-flex-1">{{ $t('buttons.filter')
                        }}</a-button>
                    <a-action-button @click="onClearFilter" intent="simple" icon="clarity:filter-off-line"
                        class="tw-h-[40px] tw-w-[40px] tw-border-b-2"></a-action-button>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, provide, toRef } from 'vue';
import ProductFilter from './partials/ProductFilter.vue';
import SpentInFilter from './partials/SpentInFilter.vue';
import MarketerFilter from './partials/MarketerFilter.vue';

const props = defineProps(['filters', 'visible']);
const emit = defineEmits(['update:filters', 'update:visible', 'filter']);
const filters = toRef(props, 'filters');

provide('filters', filters);
provide('update:filters', v => {
    emit('update:filters', v)
});

const visible = computed({
    get: () => props.visible,
    set: v => emit('update:visible', v)
})

const onFilter = () => {
    visible.value = false;
    emit('filter');
}

const onClearFilter = () => {
    visible.value = false;
    emit('update:filters', {})
    emit('filter');
}

provide('filter', ((params = {}) => emit('filter', params)))

</script>

<style></style>