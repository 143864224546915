<template>
    <tr :key="item.id"
        class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100">
        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate" :title="item.product.name">{{ item.product.name }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-w-[70px] tw-truncate tw-capitalize">{{ item.platform }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-w-[120px] tw-truncate">{{ item.spent_in }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-1 tw-justify-start " >
                <p class="tw-truncate tw-font-bold">${{ parseFloat(item.spend).toFixed(2) }}</p>
            </div>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-1 tw-justify-start " :class="[item.results == 0 ? 'tw-text-amber-500' : 'tw-text-emerald-500']">
                <p class="tw-truncate tw-font-bold">{{ item.results }}</p>
                <icon icon="solar:chart-2-bold-duotone" />
            </div>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-1 tw-justify-start " :class="[item.results == 0 ? 'tw-text-amber-500' : 'tw-text-violet-500']">
                <p class="tw-truncate tw-font-bold">${{ item.results > 0 ? (item.spend / item.results).toFixed(2) : 0 }}</p>
            </div>
        </td>

        <td class="tw-px-5 tw-pr-0 tw-py-">
            <AdTableActions :item="item" />
        </td>
    </tr>
</template>

<script setup>
import { defineProps, toRef} from "vue";
import AdTableActions from "./AdTableActions";
const props = defineProps(["item"]);
const item = toRef(props, "item");

</script>

<style></style>